<template>
  <div class="driver" v-if="order.delivery_status != 'delivering' && order.delivery_status != 'delivered'">
    <img src="../assets/bicycle.svg">
    <span>{{ order.driver_name }}</span>
    <span v-if="order.delivery_status == 'arrived'">Angekommen</span>
    <span v-else-if="order.expected_time">{{ formatGuess(delivery(order)) }}</span>
  </div>
</template>

<script>
export default {
  props: ['order'],
  methods: {
    delivery(order) {
      return order.expected_time - this.$store.state.serverTime
    }
  }
}
</script>

